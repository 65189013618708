import React, { useState, useEffect } from 'react';
import './StepNavigation.css'; // For CSS styles

const StepNavigation = ({ UrlLink, totalSteps, onNext, onFinish, onSkip, errors }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [claimButtonText, setclaimButtonText] = useState('Claim'); 

   

  const handleNext = () => {

    if (window.navigator && window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
      onNext(currentStep);
    }
  };

  
  const handleClaimClick = () => {
    if (window.navigator && window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
    if (claimButtonText == 'Claim') {
      setclaimButtonText('Finish');
      const url = `https://t.me/share/url?url=` + encodeURIComponent(UrlLink);
      //window.location.href = url; 
      window.open(url, '_blank');
    }
    else {
      onFinish(false);
    }
  };



  return (
    <div className="step-navigation">
      <span style={  { color: 'white' }} className="step-counter">  {currentStep} of {totalSteps}</span>
      {currentStep == 4 ?
        <>
          <a
            style={ { color: 'white' }}
            className="step-counter"
            onClick={onSkip}
          >
            Skip
          </a>
          <a
            style={ { color: 'white' }}
            className="step-counter"
            onClick={handleClaimClick} >
            {claimButtonText}
          </a>

        </>
        :
        <> 
          <a
            style={{ color: 'white' }}
            href="#"
            className="step-counter"
            onClick={handleNext}
            aria-disabled={currentStep === totalSteps}
          >
            Next
          </a>
        </>
      }



    </div>
  );
};

export default StepNavigation;
