import React, { useState, useEffect } from 'react';
import './LandingPage.css';
import toast, { Toaster } from 'react-hot-toast';
import BeatLoader from "react-spinners/BeatLoader";
const LandingPage = ({ OnLandingCompleted }) => {

  useEffect(() => {
    setTimeout(() => {
      OnLandingCompleted();
    }, 3000);

  }, []);




  return (
    <div className={`landing-container-black`}>
      <div className="landing-content">
        <div className="home-image-container">
          <img src={`images/onboarding/landing.gif`} alt="buddy" className="loading-animation" />
        </div>

        <span style={{ color: '#CDEB45', fontSize: '38px', fontFamily: 'Casino' }} >CRIMSON WHEEL</span>
        <BeatLoader style={{ marginTop: '15px', textAlign: 'center' }}
          color="#CDEB45"
          loading={true}
          size={15}
          aria-label="Loading Spinner"
        />
        <p style={{ color: 'white' }} >
          <div style={{ fontSize: 'larger' }}>
            Stay Tuned
          </div>
          <div style={{ color: 'white' }} >More info on our official channels</div>
        </p>
        <div>
          <img src='images/social/Tele.png' height={24} width={24} style={{ verticalAlign: 'text-bottom', marginRight: '20px' }} />
          <img src='images/social/X.png' height={24} width={24} style={{ verticalAlign: 'text-bottom', marginRight: '20px' }} />
          <img src='images/social/YT.png' height={24} width={24} style={{ verticalAlign: 'text-bottom', marginRight: '20px' }} />

        </div>


      </div>
    </div>
  );
};

export default LandingPage;
