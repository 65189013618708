// components/App.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import './App.css';
import Game from './components/maingame/Game';
import Tabs from './components/menu-tabs/Tabs';
import MiningCards from './components/menu/MiningCards';
import apiService from './services/apiService';
import loginService from './services/loginService';
import userService from './services/userService';
import UserFriend from './components/buddy/UserFriend';
import Onbording from './components/onboarding/onboarding';
import LandingPage from './components/home/LandingPage';
import ErrorMessage from './components/common/ErrorMessage';
import Stats from './components/bakery/Stats';
import Coins from './components/bakery/Coins';
import Boosters from './components/bakery/Boosters';
import Tasks from './components/gifts/Tasks';
import CollectionActionDialog from './components/common/CollectionActionDialog';
import Airdrop from './components/shop/Airdrop';
import UserLevel from './components/user-level/UserLevel';
import UserFriendStats from './components/buddy/UserFriendStats';

function App() {
  const [balance, setBalance] = useState(0);
  const [activeTab, setActiveTab] = useState('bakery');
  const [energy, setEnergy] = useState(1000);
  const [userData, setUserData] = useState('');
  const [hourlyProfitRate, setHourlyProfitRate] = useState(0);
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [upgradeError, setUpgradeError] = useState('');
  const [maxEnergy, setMaxEnergy] = useState(1000);
  const [ratePerClick, setRatePerClick] = useState(1);
  const [fullTankTimeLeft, setFullTankTimeLeft] = useState(null);
  const [showDailyReward, setShowDailyReward] = useState(false);
  const [showBoosters, setShowBoosters] = useState(false);
  const [IsDisplayWelcome, setIsDisplayWelcome] = useState(false);
  const [showTabs, setShowTabs] = useState(false);
  const [bakeryPage, setBakeryPage] = useState('landing');
  const energyRef = useRef(energy);

  const fetchCards = async () => {
    try {
      const response = await apiService.get('/user-cards?category=2');
      const cardData = response.data.data || [];
      setCards(Array.isArray(cardData) ? cardData : []);
      if (cardData) {
        let energyCard = cardData.find(x => x.key === 'energy');
        if (energyCard) {
          setMaxEnergy(energyCard.maxEnergy || 1000);
        }
        let clickCard = cardData.find(x => x.key === 'multitap');
        if (clickCard) {
          console.log("clickCard.ratePerClick", clickCard.ratePerClick);
          setRatePerClick(clickCard.ratePerClick || 1);
          localStorage.setItem("ratePerClick", clickCard.ratePerClick || 1);
        }
        let fullTankCard = cardData.find(x => x.key === 'fulltank');
        if (fullTankCard) {

          const finalTime = new Date(fullTankCard.lastActionTime);
          const cooldownTimeInSeconds = fullTankCard.coolDownTime; // Assuming this is in seconds

          finalTime.setSeconds(finalTime.getSeconds() + cooldownTimeInSeconds);


          // Calculate the difference between now and the cooldown expiration time
          const currentTime = new Date(fullTankCard.currentTime);

          const timeDifferenceInSeconds = Math.max((finalTime - currentTime) / 1000, 0);
          setFullTankTimeLeft(timeDifferenceInSeconds || 0);
        }
      }
    } catch (error) {
      console.error('Error fetching boosters cards:', error);
    }
  };

  const resetFullTank = async () => {
    try {
      const response = await apiService.post('/reset-full-tank');
      //if (response.data.data){}
    } catch (error) {
      console.error('Error fetching energy:', error);
    }
  };

  useEffect(() => {
    resetFullTank();
    fetchCards();

  }, []);


  useEffect(() => {
    energyRef.current = energy;
  }, [energy]);

  useEffect(() => {
    //const telegramData = { telegramId: '1400153577-sbtrwt' };
     // const telegramData = { telegramId: '512974185-Themvda' };
     const telegramData = { telegramId: '6920243159-undefined' }; 
    if (window.Telegram && window.Telegram.WebApp.initDataUnsafe.user) {
      const { photo_url, first_name, last_name, username, language_code, id } = window.Telegram.WebApp.initDataUnsafe.user;
      telegramData.telegramId = `${id}-${username}`;
      telegramData.first_name = first_name;
      telegramData.last_name = last_name;
      telegramData.username = username;
      telegramData.language_code = language_code;
      telegramData.photo_url = photo_url;
      /*    telegramData.is_bot = is_bot;
         telegramData.allows_write_to_pm = allows_write_to_pm; */
      telegramData.telegramAppCode = '' + id;
      window.Telegram.WebApp.expand();
    }

    loginService.getToken(telegramData)
      .then(data => {
        if (window.Telegram && window.Telegram.WebApp.initDataUnsafe) {
          const startParam = window.Telegram.WebApp.initDataUnsafe.start_param; 
          if (startParam) {
            userService.setFriend({ refId: startParam })
              .then(data => {
                console.log('Token received:', data.token);
              })
              .catch(error => {
                console.error('Error fetching token:', error);
              });
          }
        }

        console.log('Token received:', data.token);
      })
      .catch(error => {
        console.error('Error fetching token:', error);
      });

    // Fetch initial balance, energy, and hourly profit rate from the server when the app loads
    const fetchInitialData = async () => {
      try {       
        const balanceResponse = await apiService.get('/balance');
        if (balanceResponse.data.data) {
          setBalance(balanceResponse.data.data.balance);
          if (window.Telegram.WebApp) {
            setUserData(window.Telegram.WebApp.initDataUnsafe.user.first_name);
          }
        }



        const energyResponse = await apiService.get('/energy');
        if (energyResponse.data.data) {
          setEnergy(energyResponse.data.data.energyCount);
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchInitialData();
  }, []);



  useEffect(() => {
    const updateProfitInterval = setInterval(() => {
      apiService.post('/update-user-profit')
        .then(response => {

          if ('' + response.data.status === '1') {
            setBalance(response.data.data.balance);
          } else {
            console.error('Failed to update user profit:', response.data.message);
          }
        })
        .catch(error => {
          console.error('Error updating user profit:', error);
        });
    }, 3000);

    return () => clearInterval(updateProfitInterval);
  }, []);

  useEffect(() => {
    const fetchEnergy = async () => {
      try {
        const response = await apiService.get('/energy');
        if (response.data.data) setEnergy(response.data.data.energyCount);
      } catch (error) {
        console.error('Error fetching energy:', error);
      }
    };

    fetchEnergy();
  }, []);

  useEffect(() => {
    const fetchHourProfit = async () => {
      try {
        const response = await apiService.get('/user-hourprofitrate');
        if (response.data.data) setHourlyProfitRate(response.data.data.hourProfit);
      } catch (error) {
        console.error('Error fetching hourly profit rate:', error);
      }
    };

    fetchHourProfit();
  }, []);

  useEffect(() => {
    const energyRegenerationInterval = setInterval(() => {
      setEnergy(prevEnergy => Math.min(prevEnergy + 1, maxEnergy));
    }, 1000);

    return () => clearInterval(energyRegenerationInterval);
  }, [energy]);

  const handleBalanceIncrease = useCallback(async () => {
    const rate = parseInt(localStorage.getItem("ratePerClick")) || 1;

    if (energyRef.current < rate) {

      setTimeout(() => setUpgradeError(''), 3000); // Clear error after 3 seconds
      return; // Exit the function to prevent API call
    }


    if (energyRef.current >= rate) {
      try {
        if (window.navigator && window.navigator.vibrate) {
          window.navigator.vibrate(2);
        }
        const response = await apiService.post('/balance', { amount: parseInt(rate) });
        if (response.data.data) setBalance(response.data.data.balance);
      } catch (error) {
        console.error('Error increasing balance:', error);
      }
    }
  }, []);

  const handleEnergyIncrease = useCallback(async () => {

    try {
      const rate = parseInt(localStorage.getItem("ratePerClick")) || 1; 
      if (energyRef.current >= rate) {
        setEnergy(prevEnergy => {  
          const newEnergy = parseInt(Math.max((prevEnergy - rate), 0));
          if (prevEnergy > rate) { apiService.post('/energy', { amount: newEnergy }); }

          return newEnergy;

        });
      }

    } catch (error) {
      console.error('Error increasing energy:', error);
    }

  }, []);

  const handleCardUpgrade = useCallback(async () => {
    try {
      const balanceResponse = await apiService.get('/balance');
      if (balanceResponse.data.data) setBalance(balanceResponse.data.data.balance);

      const bprofitResponse = await apiService.get('/user-hourprofitrate');
      if (bprofitResponse.data.data) setHourlyProfitRate(bprofitResponse.data.data.hourProfit);
    } catch (error) {
      console.error('Error updating balance after card upgrade:', error);
    }
  }, []);

  const handleCardClick = (card) => {
    if (card.key === "fulltank") {
      console.log(fullTankTimeLeft);
      if (fullTankTimeLeft > 0) {
        console.log('Cannot select fulltank card yet. Please wait.');
        return;
      }
    }

    setSelectedCard(card);

  };

  const handleConfirmUpgrade = async () => {
    try {
      if (selectedCard.isUpgradable) {
        const response = await apiService.post('/upgrade-user-card', { cardID: selectedCard._id });
        if (response.data.status === 1) {
          fetchCards();
          handleCardUpgrade();
        } else {
          setUpgradeError(response.data.message || 'Failed to upgrade the card');
        }
      }
      if (selectedCard.key === "fulltank") {
        const response = await apiService.post('/set-full-energy', { amount: maxEnergy });
        if (response.data.status === 1) {
          fetchCards();
          setEnergy(maxEnergy);
          //handleCardUpgrade();
        } else {
          setUpgradeError(response.data.message || 'Failed to full energy');
        }

      }
    } catch (error) {
      setUpgradeError('Error upgrading the card. Please try again later.');
    } finally {
      setSelectedCard(null);
    }

  };

  const handleCancelUpgrade = () => {
    setSelectedCard(null);
    setUpgradeError('');
  };

  const handleOnBoardingClaim = async (isSkipped) => {
    const status = isSkipped ? 'Skiped' : 'Claimed';
    const boardingresponse = await apiService.post('/onboardingStatus', { status: status });
    console.log('boardingresponse', boardingresponse.data);
    if (!isSkipped) {
      const response = await apiService.post('/balance', { amount: 150000 });
      if (response.data.data) setBalance(response.data.data.balance);
      setIsDisplayWelcome(true);
    }
    setBakeryPage('buddies');
  };

  const handleOnLandingCompleted = async () => {
    const balanceResponse = await apiService.get('/balance');
    if (balanceResponse.data.data) {
      if (balanceResponse.data.data.onBoardingStatus == null || balanceResponse.data.data.onBoardingStatus == '') {
        setBakeryPage('onboarding');
      }
      else {
        setBakeryPage('buddies');
      }

    }

  };

  const handleProfileClick = async () => {
    console.log('app.js handleProfileClick');
    setBakeryPage('userStats');
  };

  const handleOnStatusExit = async () => {
    
    setBakeryPage('buddies');
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (fullTankTimeLeft !== null && fullTankTimeLeft > 0) {
        setFullTankTimeLeft(prev => Math.max(prev - 1, 0));
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [fullTankTimeLeft]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hours}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };



  const toggleDailyReward = () => setShowDailyReward(prev => !prev);
  const toggleBoosters = () => setShowBoosters(prev => !prev);
  const backToBakery = () => setActiveTab('bakery');
  const backToBakeryPage = () => setBakeryPage('bakery');

  const renderBakery = () => {
    switch (bakeryPage) {
      case 'bakery':
        return (
          !showBoosters ?
            <>
              <div id="game-container">
                {/* <Header 
                toggleBoosters={toggleBoosters} 
              /> */}
                <Stats hourlyProfitRate={hourlyProfitRate} ratePerClick={ratePerClick} setActiveTab={setBakeryPage} toggleBoosters={toggleBoosters} />
                <Coins balance={balance} />
              </div>
              <div id="phaser-game">
                <Game onBalanceIncrease={handleBalanceIncrease} onEnergyDecrease={handleEnergyIncrease} energy={energy} ratePerClick={ratePerClick} />
              </div>
              {/* <div className='booseter-container'>
              <button className='booster-btn' onClick={toggleBoosters}><img src='images/booster/booster.svg' height={20} width={20}/>Boosters</button>
            </div> */}
            </> : <div>
              <Boosters cards={cards}
                handleCardClick={handleCardClick}
                fullTankTimeLeft={fullTankTimeLeft}
                formatTime={formatTime}
                balance={balance}
                toggleBoosters={toggleBoosters}
              />
            </div>
        );
      case 'user-level':
        return (<UserLevel backToBakery={backToBakeryPage} />);
      case 'buddies':
        return (<UserFriend IsDisplayWelcome={IsDisplayWelcome} balance={balance} onProfileClick={handleProfileClick} />);
      case 'onboarding':
        return (<Onbording balance={balance} OnFinish={handleOnBoardingClaim} />);
      case 'landing':
        return (<LandingPage OnLandingCompleted={handleOnLandingCompleted} />);
      case 'userStats':
        return (<UserFriendStats OnStatusExit={handleOnStatusExit}/>);

      default:
        return null;
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'bakery':
        return renderBakery();
      case 'menu':
        return <div>
          <MiningCards onCardUpgrade={handleCardUpgrade} backToBakery={backToBakery} />
        </div>;
      case 'friends':
        return <UserFriend balance={balance} />;
      case 'onbording':
        return (<Onbording balance={balance} />);
      case 'userStats':
        return (<UserFriendStats OnStatusExit={setBakeryPage('buddies')} />);
      case 'gifts':
        return (
          <Tasks />

        );
      case 'shop':
        return (
          <Airdrop />
        );

      default:
        return null;
    }
  };

  return (
    <div className="App">

      <div id="phaser-container">
        {renderTabContent()}
      </div>
      {showTabs && (
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} setBakeryPage={setBakeryPage} />
      )}

      {upgradeError && (
        <ErrorMessage message={upgradeError} onClose={() => setUpgradeError('')} />
      )}
      {selectedCard && (
        <CollectionActionDialog
          title="Confirm Selection"
          message={`Are you sure you want to upgrade the ${selectedCard.name} card?`}
          onConfirm={handleConfirmUpgrade}
          onCancel={handleCancelUpgrade}
          popData={selectedCard}
        />
      )}



    </div>
  );
}

export default App;