// loginService.js
import apiService from './apiService';

const loginService = {
    login: async (credentials) => {
        try {
            const response = await apiService.post('/login', credentials);
            if (response.data.status === 1) {
                localStorage.setItem('token', response.data.token);
            }
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    logout: () => {
        localStorage.removeItem('token');
    },

    isLoggedIn: () => {
        return !!localStorage.getItem('token');
    },

    getToken: async (telegramData) => {
        try {
          const response = await apiService.post('/get-token', {telegramData : telegramData} );
          if (response.data && response.data.token) {
            console.log(response.data.token);
            localStorage.setItem('token', response.data.token);
          }
          return response.data;
        } catch (error) {
          console.error('Error getting token:', error);
          throw error;
        }
      },
};

export default loginService;
