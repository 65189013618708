
import React from 'react';
import './EarnMoreDialog.css';

const EarnMoreDialog = ({onConfirm, onCancel }) => {

  const handleOverlayClick = (event) => {
    // Check if the click was outside the modal content
    if (event.target.className === 'overlay') {
      onCancel();
    }
  };

  return (
    <div className="overlay"  onClick={handleOverlayClick}>
      <div className="earn-more-item">
      {/*   <div className="close-button" onClick={onCancel}>×</div> */}
        <h3 style={{fontSize:'30px'}}>More</h3>
        <div className="earnmore-icon">
          <img src={`images/inviteLevel/earnmore-512.png`} alt='EarnMore' width={130} height={130} />
        </div>
         <p>         
         <ul className="earnmore-list">   
             <li key="5" className="earnmore-item">
                <div className="earnmore-avatar">
                <img src={`images/inviteLevel/Level_1.png`} alt='EarnMore' width={24} height={24}   />
                </div>
                <span className="earnmore-name">5 Invites</span>
                <span className="earnmore-tokens"><img src='images/coin.png'  height={15} width={15} style={{ verticalAlign : 'middle' ,   marginRight: '0px' }} /> <spam style={{ verticalAlign : 'middle'   }} >250 K</spam> </span>
              </li>       
              <li key="10" className="earnmore-item">
                <div className="earnmore-avatar">
                <img src={`images/inviteLevel/Level_2.png`} alt='EarnMore' width={24} height={24} />
                </div>
                <span className="earnmore-name">10 Invites</span>
                <span className="earnmore-tokens"><img src='images/coin.png' height={14} width={14} style={{ verticalAlign : 'middle' ,   marginRight: '0px' }} /> <spam style={{ verticalAlign : 'middle'   }} >500 K</spam></span>
              </li>
              <li key="25" className="earnmore-item">
                <div className="earnmore-avatar">
                <img src={`images/inviteLevel/Level_3.png`} alt='EarnMore' width={24} height={24} />
                </div>
                <span className="earnmore-name">25 Invites</span>
                <span className="earnmore-tokens"><img src='images/coin.png' height={14} width={14} style={{ verticalAlign : 'middle' ,   marginRight: '0px' }} /> <spam style={{ verticalAlign : 'middle'   }} >2.5 M</spam></span>
              </li>
              <li key="50" className="earnmore-item">
                <div className="earnmore-avatar">
                <img src={`images/inviteLevel/Level_4.png`} alt='EarnMore' width={24} height={24} />
                </div>
                <span className="earnmore-name">50 Invites</span>
                <span className="earnmore-tokens"><img src='images/coin.png' height={14} width={14} style={{ verticalAlign : 'middle' ,   marginRight: '0px' }} /> <spam style={{ verticalAlign : 'middle'   }} >5 M</spam></span>
              </li>
              <li key="100" className="earnmore-item">
                <div className="earnmore-avatar">
                <img src={`images/inviteLevel/Level_5.png`} alt='EarnMore' width={24} height={24} />
                </div>
                <span className="earnmore-name">100 Invites</span>
                <span className="earnmore-tokens"><img src='images/coin.png' height={14} width={14} style={{ verticalAlign : 'middle' ,   marginRight: '0px' }} /> <spam style={{ verticalAlign : 'middle'   }} >10 M</spam></span>
              </li>
               
          </ul>
        </p>        
       {/*  <button className="confirm-button" onClick={onConfirm}>Close</button> */}
      </div>

    </div>
  );
};

export default EarnMoreDialog;
