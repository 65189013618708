import React, { useState, useEffect } from 'react';
import apiService from '../../services/apiService';
import utilities from '../../services/utilities';
import './UserFriendStats.css';


const UserFriendStats = ({ OnStatusExit }) => {
  const [friends, setFriends] = useState({});
  const [top10UsersByFriends, setUsersByFriends] = useState([]);
  const [top10UsersByBalance, setUsersByBalance] = useState([]);
  const [loading, setLoading] = useState(true);


  

  const fetchUserStats = async () => {
    try {
      const response = await apiService.get('/user-stats');
      const data = response.data.data || {}; 
      setFriends(data);
      setUsersByBalance(Array.isArray(data.top10UsersByBalance) ? data.top10UsersByBalance : []);
      setUsersByFriends(Array.isArray(data.top10UsersByFriends) ? data.top10UsersByFriends : []);
    } catch (error) {
      console.error('Error fetching user stats:', error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchUserStats();
  }, []);
 
  return (
    <div style={{ width: "98%" }} className="user-friend-container">
   {loading ? (
      <h2 style={{ color: "white" , textAlign:'center' }}>Loading...</h2> 
    ) : ( 
      <div className="user-friend-content">
        <span style={{ color: "white" }} onClick={OnStatusExit}>Go Back</span>
        <h3 style={{ color: "white" }}>User Data Stats</h3> 
        <div className="friends-container">
          <h2 style={{ color: '#CDEB45', textAlign: "left", marginBottom: '0px', marginTop: '10px' }}>Total Accounts</h2>
          <spam style={{ color: 'white', textAlign: "left", marginBottom: '10px' }}>{friends.totalUsers}</spam>

          <h2 style={{ color: '#CDEB45', textAlign: "left", marginBottom: '0px', marginTop: '10px' }}>Active Users</h2>
          <spam style={{ color: 'white', textAlign: "left", marginBottom: '-10px' }}>{friends.activePlayers}</spam>

          <h2 style={{ color: '#CDEB45', textAlign: "left", marginBottom: '0px', marginTop: '10px' }}>Combined Total Score</h2>
          <spam style={{ color: 'white', textAlign: "left", marginBottom: '-10px' }}>{friends.totalScore}</spam>

          <h2 style={{ color: '#CDEB45', textAlign: "left", marginBottom: '-10px', marginTop: '20px' }}>Top 10 Users By Score</h2>
          <ul className="friend-list">
            {top10UsersByBalance.map((friend, index) => (
              <li key={index} className="friend-item">
                <div className="friend-avatar">{friend.first_name[0].toUpperCase()}</div>
                <span className="friend-name">{friend.first_name}</span>
                <span className="friend-tokens"><img src='images/coin.png' height={15} width={15} style={{ verticalAlign: 'middle', marginRight: '-2px', marginTop: '10px' }} /> <span style={{ verticalAlign: 'middle' }}> {utilities.formatNumber(friend.balance || 0)}</span> </span>
              </li>
            ))}
          </ul>

          <h2 style={{ color: '#CDEB45', textAlign: "left", marginBottom: '-10px', marginTop: '20px' }}>Top 10 Users By Invite</h2>
          <ul className="friend-list">
            {top10UsersByFriends.map((friend, index) => (
              <li key={index} className="friend-item">
                <div className="friend-avatar">{friend.first_name[0].toUpperCase()}</div>
                <span className="friend-name">{friend.first_name}</span>
                <span style={{ verticalAlign: 'middle' }}> {utilities.formatNumber(friend.friendCount || 0)}</span>
              </li>
            ))}
          </ul>
        </div> 
      </div>)}

    </div>
  );
};

export default UserFriendStats;
